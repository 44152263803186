<cdk-virtual-scroll-viewport itemSize="100" class="example-viewport">
    <mat-card *ngFor="let route of routes | async | search:term">
        <mat-card-header>
            <mat-card-title>{{route.name}}</mat-card-title>
            <mat-card-subtitle>{{route.description}}</mat-card-subtitle>
        </mat-card-header>
        <button mat-button (click)="onDetails(route)">
            <mat-icon>reorder</mat-icon>
        </button>
        <button mat-button (click)="onUpdate(route)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-button (click)="onDelete(route)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-card>
</cdk-virtual-scroll-viewport>
<button mat-fab (click)="onCreate()">
    <mat-icon>add</mat-icon>
</button>