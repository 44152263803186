import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '../../services/storage.service';

import { Client } from '../../models/client';
import { Dialogs } from 'src/app/dialogs/dialogs';

@Component({
    selector: 'app-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.css']
})

export class ClientListComponent {

    @Input()
    term: string;

    clients: Observable<Client[]>;

    client: Client;

    constructor(public storage: StorageService, public dialogs: Dialogs) {
        this.clients = storage.getClients();
    }

    onCreate(): void {
        this.dialogs.openClientDialog(null).subscribe(client => {
            this.createClient(client);
        });
    }

    onCall(client: Client) {
        window.open('tel:' + client.telephone, '_blank');
    }

    onNavigate(client: Client) {
        window.open('https://maps.google.com/maps?daddr=' + client.address, '_self');
    }

    onUpdate(client: Client) {
        this.dialogs.openClientDialog(client.copy()).subscribe(client => {
            this.updateClient(client);
        });
    }

    createClient(client: Client) {
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'address': client.address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                client.latitude = results[0].geometry.location.lat();
                client.longitude = results[0].geometry.location.lng();

                results[0].address_components.forEach(component => {
                    if (component.types.length > 0 && component.types[0] == "postal_code") {
                        client.postalcode = component.long_name;
                    }
                })

                this.storage.createClient(client);
            }
            else {
                this.dialogs.openAlertDialog('Error!', 'Address \'' + client.address + '\' unknown!');
            }
        });
    }

    updateClient(client: Client) {
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({ 'address': client.address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                client.latitude = results[0].geometry.location.lat();
                client.longitude = results[0].geometry.location.lng();

                results[0].address_components.forEach(component => {
                    if (component.types.length > 0 && component.types[0] == "postal_code") {
                        client.postalcode = component.long_name;
                    }
                })

                this.storage.updateClient(client);
            }
            else {
                this.dialogs.openAlertDialog('Error!', 'Address \'' + client.address + '\' unknown!');
            }
        });
    }

    onDelete(client: Client) {
        this.dialogs.openConfirmDialog('Delete client?', 'Are you sure to delete client \'' + client.name + '\'?').subscribe(() => {
            this.storage.deleteClient(client);
        })
    }
}
