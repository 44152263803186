import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { StorageService } from '../../services/storage.service';

import { Client } from 'src/app/models/client';
import { Route } from 'src/app/models/route';

@Component({
    selector: 'app-route-details',
    templateUrl: './route-details.component.html',
    styleUrls: ['./route-details.component.css']
})

export class RouteDetailsComponent implements OnDestroy {

    @Input()
    public term: string;

    @Input()
    public route: Route;

    @Output()
    public directions = new EventEmitter();

    clients: Observable<Client[]>;

    subscription: Subscription;

    constructor(public storage: StorageService) {
    }

    ngOnChanges() {
        if (this.route == null) {
            return;
        }

        this.clients = this.storage.getRouteClients(this.route);

        this.subscription = this.clients.subscribe(clients => {
            this.route.clients = clients.filter(a => a.priority != -1).sort((a, b) => a.priority - b.priority);
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getPriorityIcon(client: Client) {
        return ['check_box_outline_blank', 'looks_one', 'looks_two', 'looks_3', 'looks_4', 'looks_5'][client.priority];
    }

    onInclude(client: Client) {
        this.storage.includeClient(this.route, client);
    }

    onExclude(client: Client) {
        this.storage.excludeClient(this.route, client);
    }

    onPriority(client: Client) {
        this.storage.setPriority(this.route, client);
    }

    onCall(client: Client) {
        window.open('tel:' + client.telephone, '_blank');
    }

    onNavigate(client: Client) {
        window.open('https://maps.google.com/maps?daddr=' + client.address, '_self');
    }

    onGenerate() {
        this.directions.emit(this.route);
    }
}
