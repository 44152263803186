import { Client } from './client';

export class Route {
    id: string;
    name: string;
    description: string;
    clients: Client[];

    copy(): Route {
        var result = new Route();
        result.id = this.id;
        result.name = this.name;
        result.description = this.description;
        result.clients = this.clients;
        return result;
    }

    data(): any {
        return {
            name: this.name == null ? '' : this.name,
            description: this.description == null ? '' : this.description,
        }
    }
}
