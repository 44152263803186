import { Component } from '@angular/core';

import { StorageService } from './services/storage.service';
import { Authentication } from './components/authentication/authentication';

import { Client } from './models/client';
import { Route } from './models/route';
import { Dialogs } from './dialogs/dialogs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'RouteMan';

    user: firebase.User;

    index = 0;

    route: Route;

    term: string;

    constructor(public storage: StorageService, public authentication: Authentication, public dialogs: Dialogs) {
        authentication.authState().subscribe(user => {
            this.user = user;

            this.onSelect(user ? 2 : 0);

            if (!user) {
                this.onSignIn();
            }
        })
    }

    onSignIn() {
        if (this.user) {
            return;
        }

        this.authentication.signIn();
    }

    onSignOut() {
        if (!this.user) {
            return;
        }

        this.dialogs.openConfirmDialog('Sign off?', 'Are you sure to sign off?').subscribe(() => {
            this.authentication.signOut();
        })
    }

    onFullscreen() {
        document.documentElement.requestFullscreen();
    }

    onBack() {
        this.onSelect(this.index == 4 ? 3 : 2);
    }

    onSelect(index: number) {
        this.term = '';

        this.index = index;

        var name = this.route == null ? '' : this.route.name;

        this.title = ['RouteMan', 'Clients', 'Routes', name, name][index];
    }

    onDetails(route: Route) {
        this.route = route;

        this.onSelect(3);
    }

    onDirections(route: Route) {
        this.route = route;

        this.onSelect(4);
    }

    onImport() {
        this.dialogs.openImportDialog().subscribe(data => {
            const items = JSON.parse(data);
            items.forEach(item => {
                const client = new Client();
                client.name = item.name == null ? '' : item.name;
                client.address = item.address == null ? '' : item.address;
                client.telephone = item.telephone == null ? '' : item.telephone;
                this.storage.createClient(client);
            });
        })
    }
}