import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from '../models/client';
import { Route } from '../models/route';
import { Dialogs } from '../dialogs/dialogs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(public firestore: AngularFirestore, public dialogs: Dialogs) { }

    getClients(): Observable<Client[]> {
        return this.firestore.collection<Client>('clients').snapshotChanges()
            .pipe(
                map(items => {
                    const result = new Array();
                    items.forEach(item => {
                        const client = new Client();
                        client.id = item.payload.doc.id;
                        client.name = item.payload.doc.data().name;
                        client.address = item.payload.doc.data().address;
                        client.telephone = item.payload.doc.data().telephone;
                        client.latitude = item.payload.doc.data().latitude;
                        client.longitude = item.payload.doc.data().longitude;
                        client.postalcode = item.payload.doc.data().postalcode;
                        result.push(client);
                    });
                    return result.sort((a, b) => a.name.localeCompare(b.name));
                })
            )
    }

    getRoutes(): Observable<Route[]> {
        return this.firestore.collection<Route>('routes').snapshotChanges()
            .pipe(
                map(items => {
                    const result = new Array();
                    items.forEach(item => {
                        const route = new Route();
                        route.id = item.payload.doc.id;
                        route.name = item.payload.doc.data().name;
                        route.description = item.payload.doc.data().description;
                        route.clients = new Array();
                        result.push(route);
                    });
                    return result.sort((a, b) => a.name.localeCompare(b.name));
                })
            )
    }

    getRouteClients(route: Route): Observable<Client[]> {
        return combineLatest(this.getClients(), this.firestore.collection('routes').doc(route.id).collection('clients').valueChanges())
            .pipe(
                map(([clients, routeclients]) => {
                    clients.forEach(client => {
                        client.priority = -1;
                        routeclients.forEach(item => {
                            if (client.id == item.client) {
                                client.priority = item.priority;
                            }
                        })
                    })
                    return clients;
                })
            );
    }

    includeClient(route: Route, client: Client) {
        this.firestore.collection('routes').doc(route.id).collection('clients').add({
            client: client.id,
            priority: 0,
        }).catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }

    excludeClient(route: Route, client: Client) {
        const self = this;
        const query = this.firestore.collection('routes').doc(route.id).collection('clients').ref.where('client', '==', client.id);
        query.get().then(items => {
            items.forEach(item => {
                self.firestore.collection('routes').doc(route.id).collection('clients').doc(item.id).delete().catch((error) => {
                    this.dialogs.openAlertDialog('Error!', error);
                });
            });
        });
    }

    setPriority(route: Route, client: Client) {
        const self = this;
        var priority = 0;
        const query = this.firestore.collection('routes').doc(route.id).collection('clients').ref.where('priority', '>', client.priority);
        query.get().then(items => {
            if (client.priority == 0) {
                items.forEach(item => {
                    priority = Math.max(priority, item.data().priority);
                });
                priority = priority + 1;
            } else {
                items.forEach(item => {
                    self.firestore.collection('routes').doc(route.id).collection('clients').doc(item.id).update({
                        client: item.data().client,
                        priority: item.data().priority - 1,
                    }).catch((error) => {
                        this.dialogs.openAlertDialog('Error!', error);
                    });
                });
            }
            if (priority <= 5) {
                const query = self.firestore.collection('routes').doc(route.id).collection('clients').ref.where('client', '==', client.id);
                query.get().then(items => {
                    items.forEach(item => {
                        self.firestore.collection('routes').doc(route.id).collection('clients').doc(item.id).update({
                            client: item.data().client,
                            priority: priority,
                        }).catch((error) => {
                            this.dialogs.openAlertDialog('Error!', error);
                        });
                    });
                });
            }
        });
    }

    createClient(client: Client) {
        this.firestore.collection('clients').add(client.data()).catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }

    updateClient(client: Client) {
        this.firestore.collection('clients').doc(client.id).update(client.data()).catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }

    deleteClient(client: Client) {
        this.firestore.collection('clients').doc(client.id).delete().catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });

        this.getRoutes().subscribe(routes => {
            routes.forEach(route => {
                this.excludeClient(route, client);
            })
        })
    }

    createRoute(route: Route) {
        this.firestore.collection('routes').add(route.data()).catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }

    updateRoute(route: Route) {
        this.firestore.collection('routes').doc(route.id).update(route.data()).catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }

    deleteRoute(route: Route) {
        this.firestore.collection('routes').doc(route.id).delete().catch((error) => {
            this.dialogs.openAlertDialog('Error!', error);
        });
    }
}
