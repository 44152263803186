import { Component, Input, ViewChild, ElementRef, ApplicationRef } from '@angular/core';

import { StorageService } from '../../services/storage.service';

import { Client } from 'src/app/models/client';
import { Route } from 'src/app/models/route';
import { Dialogs } from '../../dialogs/dialogs';

@Component({
    selector: 'app-route-directions',
    templateUrl: './route-directions.component.html',
    styleUrls: ['./route-directions.component.css']
})

export class RouteDirectionsComponent {

    @Input()
    public route: Route;

    clients: Client[];

    @ViewChild('map') mapView: ElementRef;

    map: google.maps.Map;

    location: google.maps.LatLng;

    bounds: google.maps.LatLngBounds;

    constructor(public storage: StorageService, public appRef: ApplicationRef, public dialogs: Dialogs) { }

    ngAfterViewInit(): void {
        const mapOptions: google.maps.MapOptions = {
            zoom: 10,
            center: { lat: 52.0, lng: 5.2 },
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        }

        this.map = new google.maps.Map(this.mapView.nativeElement, mapOptions);
    }

    onCall(client: Client) {
        window.open('tel:' + client.telephone, '_blank');
    }

    onNavigate(client: Client) {
        window.open('https://maps.google.com/maps?daddr=' + client.address, '_self');
    }

    ngOnChanges() {
        if (this.route == null) {
            return;
        }

        this.clients = new Array();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.location = new google.maps.LatLng({ lat: position.coords.latitude, lng: position.coords.longitude });

                var origin = null;
                var destination = null;

                var clients = this.route.clients.filter(a => a.priority);
                var remaining = this.route.clients.filter(a => !a.priority).sort((a, b) => a.postalcode.localeCompare(b.postalcode));

                if (clients.length > 0) {
                    destination = clients.pop();
                }

                this.directions(origin, destination, clients, remaining);
            })
        }
    }

    directions(origin: Client, destination: Client, clients: Client[], remaining: Client[]) {
        var waypoints = new Array();

        clients.forEach(client => {
            waypoints.push({ location: client.address, stopover: true });
        })

        var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        var directionsRequest: google.maps.DirectionsRequest = {
            origin: origin == null ? this.location : new google.maps.LatLng(origin.latitude, origin.longitude),
            destination: destination == null ? this.location : new google.maps.LatLng(destination.latitude, destination.longitude),
            waypoints: waypoints,
            optimizeWaypoints: clients.length == 0 || clients[0].priority == 0,
            travelMode: google.maps.TravelMode.DRIVING
        }

        var directionsService = new google.maps.DirectionsService;

        directionsService.route(directionsRequest, (response, status) => {
            if (status == 'OK') {
                var route = response.routes[0];

                if (this.bounds == null) {
                    this.bounds = route.bounds;
                } else {
                    this.bounds.union(route.bounds);
                }

                this.map.fitBounds(this.bounds);

                new google.maps.Polyline({ map: this.map, path: route.overview_path, strokeColor: '#888888', strokeOpacity: 0.5, strokeWeight: 5 });

                for (var i = 0; i < route.legs.length; i++) {
                    var leg = route.legs[i];
                    var label = labels[this.clients.length % labels.length];

                    if (this.route.clients.length >= labels.length) {
                        label += Math.floor(this.clients.length / labels.length);
                    }

                    var client = null;

                    if (i < route.legs.length - 1) {
                        client = clients[route.waypoint_order[i]];
                        client.name = label + ': ' + client.name;
                    } else {
                        if (destination == null) {
                            if (origin != destination || clients.length > 0) {
                                client = new Client();
                                client.name = label + ': Back home';
                                client.address = this.location.toString();
                            }
                        } else {
                            client = destination;
                            client.name = label + ': ' + client.name;
                        }
                    }

                    if (client != null) {
                        client.distance = this.getDistance(leg.distance.value);
                        client.duration = this.getDuration(leg.duration.value);

                        this.clients.push(client);

                        new google.maps.Marker({ map: this.map, label: label, position: leg.end_location });
                    }

                    this.appRef.tick();
                }

                var ROUTE_SIZE = 25;

                var length = Math.min(ROUTE_SIZE, remaining.length);

                clients = remaining.slice(0, length);
                remaining = remaining.slice(length);

                origin = destination;

                if (length < ROUTE_SIZE) {
                    destination = null;
                } else {
                    destination = clients.pop();
                }

                if (origin != destination || clients.length > 0) {
                    this.directions(origin, destination, clients, remaining);
                }
            }
            else {
                this.dialogs.openAlertDialog('Error!', 'Directions request failed due to ' + status.replace('_', ' ').replace('_', ' '));
            }
        });
    }

    getDistance(distance: number) {
        return Math.round(distance / 1000);
    }

    getDuration(duration: number) {
        var minutes = Math.round(duration / 60);
        var seconds = Math.round(duration % 60);

        return minutes + (seconds < 10 ? ':0' : ':') + seconds;
    }
}
