<cdk-virtual-scroll-viewport itemSize="100" class="example-viewport">
    <mat-card class="map-card">
        <div #map class="map"></div>
    </mat-card>
    <mat-card *ngFor="let client of clients">
        <table>
            <tr>
                <td class="left" nowrap>
                    <mat-card-header>
                        <mat-card-title>{{client.name}}</mat-card-title>
                    </mat-card-header>
                </td>
                <td class="right" nowrap>
                    <mat-card-header>
                        <mat-card-title>{{client.distance}} km</mat-card-title>
                    </mat-card-header>
                </td>
            </tr>
            <tr>
                <td class="left" nowrap>
                    <mat-card-header>
                        <mat-card-subtitle>{{client.address}}</mat-card-subtitle>
                    </mat-card-header>
                </td>
                <td class="right" nowrap>
                    <mat-card-header>
                        <mat-card-subtitle>{{client.duration}} m</mat-card-subtitle>
                    </mat-card-header>
                </td>
            </tr>
        </table>
        <button mat-button (click)="onNavigate(client)">
            <mat-icon>navigation</mat-icon>
        </button>
        <button mat-button [disabled]="!client.telephone" (click)="onCall(client)">
            <mat-icon>phone</mat-icon>
        </button>
    </mat-card>
</cdk-virtual-scroll-viewport>