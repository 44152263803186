export class Client {
    id: string;
    name: string;
    address: string;
    telephone: string;
    latitude: number;
    longitude: number;
    postalcode: string;

    priority: number;
    distance: number;
    duration: string;

    copy(): Client {
        var result = new Client();
        result.id = this.id;
        result.name = this.name;
        result.address = this.address;
        result.telephone = this.telephone;
        return result;
    }

    data(): any {
        return {
            name: this.name == null ? '' : this.name,
            address: this.address == null ? '' : this.address,
            telephone: this.telephone == null ? '' : this.telephone,
            latitude: this.latitude == null ? '' : this.latitude,
            longitude: this.longitude == null ? '' : this.longitude,
            postalcode: this.postalcode == null ? '' : this.postalcode,
        }
    }
}
