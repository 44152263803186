import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(value: any, term: string) {
        if (!value || !term) {
            return value;
        }
        term = term.toLowerCase();

        return value.filter((item) => {
            if (item.name.toLowerCase().includes(term)) {
                return value;
            }
        })
    }
}
