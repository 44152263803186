<h3>Login</h3>
<div mat-dialog-content>
    <form>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="data.email" type="email" name="email" autocomplete="username" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="data.password" type="password" name="password"
                autocomplete="current-password" />
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="data">OK</button>
</div>