<div *ngIf="route">
    <cdk-virtual-scroll-viewport itemSize="100" class="example-viewport">
        <div *ngFor="let client of clients | async">
            <mat-card *ngIf="client.priority != -1">
                <mat-card-header>
                    <mat-card-title>{{client.name}}</mat-card-title>
                    <mat-card-subtitle>{{client.address}}</mat-card-subtitle>
                </mat-card-header>
                <button mat-button (click)="onExclude(client)">
                    <mat-icon color="accent">check_box</mat-icon>
                </button>
                <button mat-button (click)="onNavigate(client)">
                    <mat-icon>navigation</mat-icon>
                </button>
                <button mat-button [disabled]="!client.telephone" (click)="onCall(client)">
                    <mat-icon>phone</mat-icon>
                </button>
                <button mat-button (click)="onPriority(client)">
                    <mat-icon>{{getPriorityIcon(client)}}</mat-icon>
                </button>
            </mat-card>
        </div>
        <div *ngFor="let client of clients | async | search:term">
            <mat-card *ngIf="client.priority == -1">
                <mat-card-header>
                    <mat-card-title>{{client.name}}</mat-card-title>
                    <mat-card-subtitle>{{client.address}}</mat-card-subtitle>
                </mat-card-header>
                <button mat-button (click)="onInclude(client)">
                    <mat-icon>check_box_outline_blank</mat-icon>
                </button>
                <button mat-button (click)="onNavigate(client)">
                    <mat-icon>navigation</mat-icon>
                </button>
                <button mat-button [disabled]="!client.telephone" (click)="onCall(client)">
                    <mat-icon>phone</mat-icon>
                </button>
            </mat-card>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
<button mat-fab (click)="onGenerate()">
    <mat-icon>navigation</mat-icon>
</button>