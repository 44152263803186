<div [class]="user ? '' : 'splash'">
    <mat-toolbar>
        <mat-toolbar-row>
            <mat-icon class="back" *ngIf="index >= 3" (click)="onBack()">arrow_back</mat-icon>
            <div class="title" (click)="onFullscreen()">
                <h1>{{title}}</h1>
            </div>
            <div>
                <mat-icon *ngIf="user" [color]="index == 1 ? 'accent' : 'currentColor'" (click)="onSelect(1)">
                    person
                </mat-icon>
                <mat-icon *ngIf="user" [color]="index == 2 ? 'accent' : 'currentColor'" (click)="onSelect(2)">
                    navigation
                </mat-icon>
                <mat-icon *ngIf="user && user.uid == 'dZ7sLwOSsoUQHMAvldlVJouC8mB2'" (click)="onImport()">
                    cloud_download
                </mat-icon>
                <mat-icon *ngIf="user" (click)="onSignOut()">
                    exit_to_app
                </mat-icon>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="user">
            <mat-form-field floatLabel="never" class="search">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="term">
                <button matButton matSuffix *ngIf="term" mat-icon-button (click)="term=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="content">
        <app-client-list *ngIf="index == 1" [term]="term">
        </app-client-list>
        <app-route-list *ngIf="index == 2" [term]="term" (details)="onDetails($event)">
        </app-route-list>
        <app-route-details *ngIf="index == 3" [term]="term" [route]="route" (directions)="onDirections($event)">
        </app-route-details>
        <app-route-directions *ngIf="index == 4" [route]="route">
        </app-route-directions>
    </div>
</div>