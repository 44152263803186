import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

import { Dialogs } from '../../dialogs/dialogs';

@Injectable({
    providedIn: 'root'
})
export class Authentication {

    constructor(public authentication: AngularFireAuth, public dialogs: Dialogs) {

    }

    authState(): Observable<firebase.User> {
        return this.authentication.authState;
    }

    signIn() {
        this.dialogs.openLoginDialog('', '').subscribe(data => {
            this.authentication.signInWithEmailAndPassword(data.email, data.password).then(() => {
            }).catch((error) => {
                this.dialogs.openAlertDialog('Error!', error).subscribe(() => {
                    this.signIn();
                });
            })
        });
    }

    signOut() {
        this.authentication.signOut();
    }
}
