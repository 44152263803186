<h3>{{title}}</h3>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput [(ngModel)]="data.address">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Telephone</mat-label>
        <input matInput [(ngModel)]="data.telephone">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="data">OK</button>
</div>