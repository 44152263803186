<h3>Import clients</h3>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Data</mat-label>
        <textarea matInput [(ngModel)]="data"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
    <button mat-button [mat-dialog-close]="data">Ok</button>
</div>