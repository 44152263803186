import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Authentication } from './components/authentication/authentication';
import { Dialogs, LoginDialog, AlertDialog, ConfirmDialog, RouteDialog, ClientDialog, ImportDialog } from './dialogs/dialogs';

import { ClientListComponent } from './components/client-list/client-list.component';
import { RouteListComponent } from './components/route-list/route-list.component';
import { RouteDetailsComponent } from './components/route-details/route-details.component';
import { RouteDirectionsComponent } from './components/route-directions/route-directions.component';
import { SearchPipe } from './search-pipe';

@NgModule({
    declarations: [
        AppComponent,
        ClientListComponent,
        RouteListComponent,
        RouteDetailsComponent,
        RouteDirectionsComponent,
        LoginDialog,
        AlertDialog,
        ConfirmDialog,
        ClientDialog,
        RouteDialog,
        ImportDialog,
        SearchPipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ScrollingModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatToolbarModule,
    ],
    providers: [Authentication, Dialogs],
    bootstrap: [AppComponent]
})
export class AppModule { }
