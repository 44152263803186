import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '../../services/storage.service';

import { Route } from '../../models/route';
import { Dialogs } from '../../dialogs/dialogs';

@Component({
    selector: 'app-route-list',
    templateUrl: './route-list.component.html',
    styleUrls: ['./route-list.component.css']
})

export class RouteListComponent {

    @Input()
    public term: string;

    @Output()
    public details = new EventEmitter();

    routes: Observable<Route[]>;

    constructor(public storage: StorageService, public dialogs: Dialogs) {
        this.routes = storage.getRoutes();
    }

    onCreate(): void {
        this.dialogs.openRouteDialog(null).subscribe(route => {
            this.storage.createRoute(route);
        });
    }

    onDetails(route: Route) {
        this.details.emit(route);
    }

    onUpdate(route: Route) {
        this.dialogs.openRouteDialog(route.copy()).subscribe(route => {
            this.storage.updateRoute(route);
        });
    }

    onDelete(route: Route) {
        this.dialogs.openConfirmDialog('Delete route?', 'Are you sure to delete route \'' + route.name + '\'?').subscribe(() => {
            this.storage.deleteRoute(route);
        })
    }
}
