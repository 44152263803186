<cdk-virtual-scroll-viewport itemSize="100" class="example-viewport">
    <mat-card *cdkVirtualFor="let client of clients | async | search:term" class="example-item">
        <mat-card-header>
            <mat-card-title>{{client.name}}</mat-card-title>
            <mat-card-subtitle>{{client.address}}</mat-card-subtitle>
        </mat-card-header>
        <button mat-button [disabled]="!client.telephone" (click)="onCall(client)">
            <mat-icon>phone</mat-icon>
        </button>
        <button mat-button (click)="onNavigate(client)">
            <mat-icon>navigation</mat-icon>
        </button>
        <button mat-button (click)="onUpdate(client)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-button (click)="onDelete(client)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-card>
</cdk-virtual-scroll-viewport>
<button mat-fab (click)="onCreate()">
    <mat-icon>add</mat-icon>
</button>