import { Component, Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Client } from '../models/client';
import { Route } from '../models/route';

@Injectable({
    providedIn: 'root'
})
export class Dialogs {

    constructor(public dialog: MatDialog, private ngZone: NgZone) {
    }

    openLoginDialog(email: string, password: string): Observable<any> {
        return this.dialog.open(LoginDialog, {
            width: '250px',
            autoFocus: false,
            data: {
                email: email,
                password: password
            }
        }).afterClosed()
            .pipe(
                filter(value => value != null)
            )
    }

    openAlertDialog(title: string, message: string): Observable<void> {
        return this.ngZone.run(() => {
            if (this.dialog.openDialogs.length > 0) {
                return;
            }
            return this.dialog.open(AlertDialog, {
                width: '250px',
                autoFocus: false,
                data: {
                    title: title,
                    message: message
                }
            }).afterClosed()
                .pipe(
                    filter(value => value)
                )
        })
    }

    openConfirmDialog(title: string, message: string): Observable<void> {
        if (this.dialog.openDialogs.length > 0) {
            return;
        }
        return this.dialog.open(ConfirmDialog, {
            width: '250px',
            autoFocus: false,
            data: {
                title: title,
                message: message
            }
        }).afterClosed()
            .pipe(
                filter(value => value)
            )
    }

    openRouteDialog(route: Route): Observable<Route> {
        return this.dialog.open(RouteDialog, {
            width: '250px',
            autoFocus: false,
            data: route
        }).afterClosed()
            .pipe(
                filter(value => value != null)
            )
    }

    openClientDialog(client: Client): Observable<Client> {
        return this.dialog.open(ClientDialog, {
            width: '250px',
            autoFocus: false,
            data: client
        }).afterClosed()
            .pipe(
                filter(value => value != null)
            )
    }

    openImportDialog(): Observable<string> {
        return this.dialog.open(ImportDialog, {
            width: '250px',
            autoFocus: false,
            data: ''
        }).afterClosed()
            .pipe(
                filter(value => value != null)
            )
    }
}

@Component({
    templateUrl: 'login-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class LoginDialog {
    constructor(public dialogRef: MatDialogRef<LoginDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}

@Component({
    templateUrl: 'alert-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class AlertDialog {
    constructor(public dialogRef: MatDialogRef<AlertDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}

@Component({
    templateUrl: 'confirm-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class ConfirmDialog {
    constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}

@Component({
    selector: 'route-dialog',
    templateUrl: 'route-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class RouteDialog {
    public title: string;

    constructor(public dialogRef: MatDialogRef<RouteDialog>, @Inject(MAT_DIALOG_DATA) public data: Route) {
        if (data == null) {
            this.title = 'New route';
            this.data = new Route();
        } else {
            this.title = 'Edit route';
        }
    }
}

@Component({
    selector: 'client-dialog',
    templateUrl: 'client-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class ClientDialog {
    public title: string;

    constructor(public dialogRef: MatDialogRef<ClientDialog>, @Inject(MAT_DIALOG_DATA) public data: Client) {
        if (data == null) {
            this.title = 'New client';
            this.data = new Client();
        } else {
            this.title = 'Edit client';
        }
    }
}

@Component({
    selector: 'import-dialog',
    templateUrl: 'import-dialog.html',
    styleUrls: ['./dialogs.css'],
})

export class ImportDialog {

    constructor(public dialogRef: MatDialogRef<ImportDialog>, @Inject(MAT_DIALOG_DATA) public data: Route) { }
}
